import React from 'react';
import { fpn } from '../config/firebase.config'

const Info = () => {
    return (
        <div id='about-section' className="bg-white py-12 mx-4 md:mx-32 font-roboto">
            <div className="flex flex-col container mx-auto md:flex-row items-center justify-between">
                <div className='pb-4 md:pb-0 md:text-left h-full max-w-xl flex flex-col'>
                    <h1 className="text-3xl md:text-4xl font-normal text-black py-1">
                        MI Roof Pro, Your Trusted Roofing Experts
                    </h1>
                    <p className="mt-2 font-normal text-sm md:text-md">
                        At MI Roof Pro, we prioritize a customer-centric approach in every aspect of our work. Our philosophy centers on listening to your needs and customizing our services to ensure your complete satisfaction. We are dedicated to building lasting relationships with our clients by delivering exceptional service and top-quality workmanship.
                    </p>
                    <div className="flex justify-center mt-12 md:mt-4">
                        <button className="bg-primary-blue hover:bg-hover-blue text-sm text-white py-2 px-8 rounded-sm font-semibold" onClick={() => {
                            const anchor = document.querySelector('#contact')
                            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                        }}>FIND OUT MORE</button>
                    </div>
                </div>
                <div>
                    <div className="pt-12 md:py-0 flex justify-center">
                        <div className="bg-primary-blue text-white w-32 h-32 md:w-44 md:h-44 p-4 flex flex-col justify-between">
                            <h2 className="text-3xl md:text-6xl font-bold text-left">25</h2>
                            <p className="text-left text-xs md:text-lg">Years of Experience</p>
                        </div>
                        <div className="bg-primary-yellow text-black w-32 h-32 md:w-44 md:h-44 p-4 flex flex-col justify-between mx-4">
                            <h2 className="text-3xl md:text-6xl font-bold text-left">7+</h2>
                            <p className="text-left text-xs md:text-lg">Areas We <br /> Service</p>
                        </div>
                        {/* <div className="bg-primary-blue text-white w-28 h-28 md:w-44 md:h-44 p-4 flex flex-col justify-between">
                            <h2 className="text-3xl md:text-6xl font-bold text-left">20</h2>
                            <p className="text-left text-xs md:text-lg">Lorem Ipsum is simply</p>
                        </div> */}
                    </div>
                    <div className="flex justify-center mt-4">
                        <h3 className="text-black px-8 py-3 font-medium text-md">
                            Residential and Commercial roofing services
                        </h3>
                    </div>
                    <div className="flex justify-center">
                        <p className='text-black text-2xl font-extrabold pr-2'>CALL: </p>
                        <a href="tel:2484993527" className="text-black text-2xl font-extrabold">{fpn}</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Info;
