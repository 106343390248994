import React from 'react';
import temp1 from '../assets/images/temp1.jpeg'

const Why = () => {


    return (
        <div id='why-choose-us' className="py-12 bg-primary-blue flex justify-center items-center w-full">
            <div className="mx-4 md:mx-32 flex flex-col md:flex-row justify-between items-center">
                <div className='text-left mr-8'>
                    <h2 className="text-3xl md:text-4xl font-bold text-white">Why Choose Us</h2>
                    <h3 className='text-md md:text-md mt-4 text-white'>
                        MI Roof Pro ensures your home is protected with durable roofing and transparent,
                        honest service.
                    </h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">

                        <div className="flex flex-col">
                            {/* <FaHelmetSafety className="text-primary-yellow text-[200px]" /> */}
                            <h3 className='text-xl text-primary-yellow font-bold'>Safety First</h3>
                            <p className="text-md text-left md:text-lg text-white"> We are committed to maintaining the highest standards of safety to protect our team on every job site.</p>
                        </div>
                        <div className="flex justify-start flex-col">
                            {/* <FaHelmetSafety className="text-primary-yellow text-[200px]" /> */}
                            <h3 className='text-xl text-primary-yellow font-bold'>Satisfaction Guarantee</h3>
                            <p className="text-md text-left md:text-lg text-white">  We are committed to ensuring that every client is fully satisfied with our services.</p>
                        </div>
                        <div className="flex justify-start flex-col">
                            {/* <FaHelmetSafety className="text-primary-yellow text-[200px]" /> */}
                            <h3 className='text-xl text-primary-yellow font-bold'>Top Rated Service</h3>
                            <p className="text-md text-left md:text-lg text-white"> Our expertise in navigating the complexities of insurance claims ensures that you receive the support and results you deserve.</p>
                        </div>


                    </div>
                </div>
                <img src={temp1} alt='Temp Description' className='w-1/2 h-1/2 mt-8 md:mt-0' />
            </div>

        </div>
    );
}

export default Why;
