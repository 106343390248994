import React, { useState, useEffect } from 'react';
import { FIREBASE_AUTH, FIRESTORE_DB, FIREBASE_STORAGE } from '../config/firebase.config';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { collection, addDoc, getDocs, query } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';

const AdminPortal = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [user, setUser] = useState(null);
    const [imageFile, setImageFile] = useState(null); // For image upload
    const [imageName, setImageName] = useState('');   // Image alt text
    const [serviceData, setServiceData] = useState({
        name: '',
        description: '',
    });
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(FIREBASE_AUTH, (user) => {
            setUser(user);
        });

        return () => unsubscribe();
    }, []);

    const handleLogIn = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(FIREBASE_AUTH, email, password);
            navigate('/admin'); // Redirect to AdminPortal after successful login
        } catch (error) {
            console.error('Error during login:', error);
        }
    };

    const handleImageUpload = (file) => {
        return new Promise((resolve, reject) => {
            const storageRef = ref(FIREBASE_STORAGE, `services/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on('state_changed',
                (snapshot) => {
                    // Optional: track progress here
                },
                (error) => {
                    reject(error);
                },
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    resolve(downloadURL);
                }
            );
        });
    };

    const handleSaveNewService = async (e) => {
        e.preventDefault();

        try {
            // Upload image to Firebase Storage
            const imageUrl = await handleImageUpload(imageFile);

            // Save the service details to Firestore
            const service = {
                serviceName: serviceData.name,
                serviceDescription: serviceData.description,
                imageUrl, // Store the image URL
                imageName, // Alt tag for the image
            };

            const userTasksCollection = collection(FIRESTORE_DB, `users/${user?.uid}/services/`);
            await addDoc(userTasksCollection, service);

            console.log("Service saved successfully!");
        } catch (error) {
            console.error("Error saving service: ", error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const userTasksCollection = collection(FIRESTORE_DB, `users/${user?.uid}/services`);
            const q = query(userTasksCollection);

            try {
                const querySnapshot = await getDocs(q);
                const fetchedServices = [];

                querySnapshot.forEach((doc) => {
                    const ServiceData = doc.data();
                    fetchedServices.push({
                        id: doc.id,
                        name: ServiceData.serviceName,
                        description: ServiceData.serviceDescription,
                        imageUrl: ServiceData.imageUrl,
                        imageName: ServiceData.imageName,
                    });
                });

                setData(fetchedServices);
            } catch (error) {
                console.error('Error fetching services: ', error);
            }
        };

        if (user) fetchData();
    }, [user]);

    const handleServiceNameChange = (e) => {
        setServiceData({ ...serviceData, name: e.target.value });
    };

    const handleServiceDescriptionChange = (e) => {
        setServiceData({ ...serviceData, description: e.target.value });
    };

    const handleImageChange = (e) => {
        setImageFile(e.target.files[0]);
    };

    const handleImageNameChange = (e) => {
        setImageName(e.target.value);
    };

    return (
        <div>
            {user ? (
                <div className='flex flex-col align-center items-center'>
                    <h2>Admin Portal</h2>

                    {data.map((service, index) => (
                        <div key={index} className='flex flex-col items-start'>
                            <h3 className='mr-4'>{service.name}</h3>
                            <p>{service.description}</p>
                            {service.imageUrl && (
                                <img src={service.imageUrl} alt={service.imageName} className="w-32 h-32" />
                            )}
                        </div>
                    ))}

                    <div className='flex flex-col items-start'>
                        <div>
                            <h3>Service Name</h3>
                            <input
                                type="text"
                                placeholder="Service Name"
                                onChange={handleServiceNameChange}
                                className='border-2 border-primary-blue my-2'
                            />
                        </div>
                        <div>
                            <h3>Service Description</h3>
                            <textarea
                                placeholder="Service Description"
                                className='my-2 border-2 border-primary-blue'
                                onChange={handleServiceDescriptionChange}
                            />
                        </div>
                        <div>
                            <h3>Service Image</h3>
                            <input type="file" accept="image/*" onChange={handleImageChange} />
                        </div>
                        <div>
                            <h3>Image Name (for Alt Text)</h3>
                            <input
                                type="text"
                                placeholder="Image Description"
                                onChange={handleImageNameChange}
                                className='border-2 border-primary-blue my-2'
                            />
                        </div>
                    </div>

                    <button
                        onClick={handleSaveNewService}
                        className="bg-primary-blue text-[10px] text-white py-1 px-6 rounded-sm font-semibold"
                    >
                        Create Service
                    </button>
                </div>
            ) : (
                <div>
                    <p>Please log in as an admin.</p>
                    <form onSubmit={handleLogIn}>
                        <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                        <input
                            type="password"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button type="submit">Login</button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default AdminPortal;
