import React from 'react';

const InsuranceClaims = () => {
    return (
        <section id='insurance-claims' className="bg-gray-50 py-16 px-6 md:px-32 font-roboto">
            <div className="container mx-auto text-center">
                {/* Title Section */}
                <h1 className="text-4xl font-bold text-primary-blue mb-8">
                    Insurance Claims Assistance
                </h1>

                <p className="text-lg text-gray-700 max-w-3xl mx-auto mb-12">
                    If you need assistance with a roofing insurance claim, contact <span className="font-semibold text-primary-blue">MI Roof Pro</span> today. Our experienced team is here to guide you through every step, from initial inspection to final settlement, ensuring you receive the support and results you need.
                </p>

                {/* Service Highlight Section */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                    <div className="bg-white p-8 shadow-lg rounded-lg">
                        <h2 className="text-2xl font-bold text-primary-blue mb-4">
                            Expert Guidance
                        </h2>
                        <p className="text-gray-600">
                            Our team is well-versed in the intricacies of insurance policies and claims processes. We provide expert guidance to help you understand your coverage and the steps required to file a successful claim.
                        </p>
                    </div>
                    <div className="bg-white p-8 shadow-lg rounded-lg">
                        <h2 className="text-2xl font-bold text-primary-blue mb-4">
                            Thorough Inspections
                        </h2>
                        <p className="text-gray-600">
                            We conduct comprehensive roof inspections to assess damage and determine the extent of repairs or replacement needed. This detailed evaluation is essential for accurately documenting your claim.
                        </p>
                    </div>
                    <div className="bg-white p-8 shadow-lg rounded-lg">
                        <h2 className="text-2xl font-bold text-primary-blue mb-4">
                            Documentation & Reporting
                        </h2>
                        <p className="text-gray-600">
                            We handle all necessary documentation and reporting for your insurance claim, ensuring all relevant information is included to minimize delays or disputes.
                        </p>
                    </div>
                    <div className="bg-white p-8 shadow-lg rounded-lg">
                        <h2 className="text-2xl font-bold text-primary-blue mb-4">
                            Advocacy & Negotiation
                        </h2>
                        <p className="text-gray-600">
                            We advocate on your behalf, negotiating with insurance adjusters to secure a fair and equitable settlement, ensuring your claim is processed efficiently.
                        </p>
                    </div>
                    <div className="bg-white p-8 shadow-lg rounded-lg">
                        <h2 className="text-2xl font-bold text-primary-blue mb-4">
                            Seamless Integration
                        </h2>
                        <p className="text-gray-600">
                            We work closely with your insurance company to streamline the claims process, coordinating all aspects of the project to reduce stress and ensure a smooth experience.
                        </p>
                    </div>
                    <div className="bg-white p-8 shadow-lg rounded-lg">
                        <h2 className="text-2xl font-bold text-primary-blue mb-4">
                            Top-Rated Service
                        </h2>
                        <p className="text-gray-600">
                            Our reputation for excellence in handling insurance claims is backed by positive customer reviews and high ratings, ensuring the best outcomes for our clients.
                        </p>
                    </div>
                </div>

                {/* Call-to-Action */}
                <div className="mt-12">
                    <button className="bg-primary-blue hover:bg-hover-blue text-sm text-white py-2 px-8 rounded-sm font-semibold" onClick={() => {
                        const anchor = document.querySelector('#contact')
                        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }}>START A CLAIM TODAY</button>
                </div>
            </div>
        </section>
    );
}

export default InsuranceClaims;
