import React from 'react'

import Logo from '../assets/images/logo-white-bg.png'
import { FaPhone } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";

const Footer = () => {
    let year = new Date().getFullYear()

    const navItems = [
        { nav: 'About', id: '#about-section' },
        { nav: 'Services', id: '#services' },
        { nav: 'Areas', id: '#areas-section' },
        { nav: 'Insurance Claims', id: '#insurance-claims' },
        { nav: 'FAQs', id: '#faqs' },
        { nav: 'Contact', id: '#contact' },
    ]

    return (
        <footer className="bg-primary-blue w-full py-8 font-roboto shadow-inner">
            <div className='mx-4 md:mx-16 lg:mx-32 flex flex-col md:flex-row md:justify-between items-center'>
                <div className='mb-6 md:mb-0'>
                    <img src={Logo} alt="MiRoofPro Logo" className='w-32 md:w-40 rounded-md mx-auto md:mx-0' />
                    <p className='text-sm text-white pt-4 text-center'>We Build Roofs, You Build Memories.</p>
                    <div className='flex flex-col items-center md:items-start md:text-left mt-4 '>
                        <div className='flex items-center mb-2'>
                            <FaPhone className='mr-2 w-4 h-4 text-primary-yellow' />
                            <a href="tel:2484993527" className="text-white">{process.env.REACT_APP_FPN}</a>
                        </div>
                        <div className='flex items-center'>
                            <IoMdMail className='mr-2 w-4 h-4 text-primary-yellow' />
                            <a href="mailto:reese@miroofpro.com" className="text-white">reese@miroofpro.com</a>
                        </div>
                    </div>
                </div>

                <div className='w-full md:w-auto'>
                    <div id='horizontal-line' className='h-[2px] my-4 bg-white'></div>
                    <ul className='text-white grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-6 text-center'>
                        {navItems.map((navItem, index) => (
                            <li key={index} className='hover:text-hover-white cursor-pointer' onClick={() => {
                                const anchor = document.querySelector(navItem.id)
                                anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                            }}>
                                {navItem.nav}
                            </li>
                        ))}
                    </ul>
                    <div id='horizontal-line' className='h-[2px] my-4 bg-white'></div>
                    <p className='text-center text-white pt-4'>Copyright &copy; {year} MI Roof Pro - All Rights Reserved.</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
