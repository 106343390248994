import React from 'react';
import Placeholder from '../assets/images/roof-1.jpg';
import MobileForm from './MobileForm';

const Hero = () => {
    return (
        <section id='hero' className="md:px-32 relative bg-cover bg-center h-screen md:pt-20 md:pb-6" style={{ backgroundImage: `url(${Placeholder})` }}>
            {/* Overlay */}
            <div className="absolute inset-0 bg-black opacity-50 z-0"></div>

            <div className="hidden md:flex flex-row container mx-auto relative z-10 text-white items-center justify-center h-full">
                <div className='w-full flex flex-col items-start md:w-1/2'>
                    <div className='max-w-xl text-left'>
                        <h1 className="text-3xl font-light mb-2 text-primary-yellow">Welcome to</h1>
                        <h2 className="text-6xl font-bold mb-6 text-white">EXPERT ROOFING SOLUTIONS</h2>
                        <p className="max-w-2xl mb-8 text-white">
                            The premier online professionals to handle your roofing needs with precision and care.
                        </p>
                    </div>
                    <div className="flex space-x-4 font-roboto">
                        <button className="bg-primary-blue text-sm text-white py-2 px-8 rounded-sm font-semibold hover:bg-hover-blue transition ease-in-out" onClick={() => {
                            const anchor = document.querySelector('#contact')
                            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                        }}>GET A QUOTE</button>
                        <button className="bg-primary-yellow text-sm text-black py-2 px-8 rounded-sm font-semibold hover:bg-hover-yellow transition ease-in-out" onClick={() => {
                            const anchor = document.querySelector('#contact')
                            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                        }}>CONTACT US</button>
                    </div>
                </div>
                <MobileForm className="hidden md:block max-h-2 font-roboto" />

            </div>

            {/* Mobile view */}
            <div className='md:hidden flex flex-col mb-4'>
                <div className=" container mx-auto w-full h-screen relative z-10 text-white">
                    <div className="flex flex-col items-center justify-center h-full px-4">
                        <h1 className="text-3xl font-light mb-2 text-center text-primary-yellow">Welcome to</h1>
                        <h2 className="text-4xl font-bold mb-8 text-center text-white">EXPERT ROOFING SOLUTIONS</h2>
                        <p className="text-center mb-8 text-white">
                            The premier online professionals to handle your roofing needs with precision and care.
                        </p>
                        <div className="flex flex-col space-y-4">
                            <button className="bg-primary-blue hover:bg-hover-blue text-sm text-white py-2 px-8 rounded-sm font-semibold w-full" onClick={() => {
                                const anchor = document.querySelector('#quote-form')
                                anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                            }}>GET A QUOTE</button>
                            <button className="bg-primary-yellow hover:bg-hover-yellow text-sm text-black py-2 px-8 rounded-sm font-semibold w-full" onClick={() => {
                                const anchor = document.querySelector('#contact')
                                anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                            }}>CONTACT US</button>
                        </div>
                    </div>


                </div>

            </div>
        </section >
    );
}

export default Hero;
