import React, { useState } from 'react';
import Logo from '../assets/images/logo-trasnparent.png'
import { fpn } from '../config/firebase.config'

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const navItems = [{ nav: 'About', id: '#about-section' }, { nav: 'Services', id: '#services' }, { nav: 'Areas', id: '#areas-section' }, { nav: 'Insurance Claims', id: '#insurance-claims' }, { nav: 'FAQs', id: '#faqs' }, { nav: 'Contact', id: '#contact' },]



    return (
        <nav className="bg-white shadow-md fixed w-full z-50 font-roboto ">
            <div className="container mx-auto flex justify-between items-center">
                <div onClick={() => {
                    const anchor = document.querySelector('#hero')
                    anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                }}>
                    <img src={Logo} alt="MiRoofPro Logo" className='w-24'></img>
                </div>

                {/* Desktop Menu */}
                <div className='flex flex-row items-center'>
                    <ul className="hidden md:flex space-x-4 mr-4 text-md text-black">
                        {navItems.map((navItem, index) => (
                            <li key={index} className="text-gray-700 hover:text-blue-600 cursor-pointer hover:text-hover-black active:text-hover-black" onClick={() => {
                                const anchor = document.querySelector(navItem.id)
                                anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                            }}>
                                {navItem.nav}
                            </li>
                        ))}
                    </ul>

                    <div className="hidden md:flex items-center space-x-4">
                        <div className="w-px h-6 bg-black"></div>
                        <div className='text-left flex flex-col'>
                            <p className='text-xs'>CALL US NOW</p>
                            <a id='phone-number' href="tel:2484993527" className="text-gray-700 hover:text-blue-600 font-semibold">{fpn}</a>
                        </div>
                    </div>
                </div>


                {/* Mobile Menu Button */}
                <div className="md:hidden mx-3">
                    <button name="Open/Close Navigation Bar " onClick={toggleMenu} className="text-gray-700 focus:outline-none">
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>

            {/* Mobile Menu */}
            {isOpen && (
                <ul className="md:hidden bg-white shadow-lg absolute w-full left-0 top-16 flex flex-col items-center space-y-4 py-4">
                    {navItems.map((navItem, index) => (
                        <li key={index} className="text-gray-700 hover:text-blue-600 cursor-pointer hover:text-hover-black active:text-hover-black" onClick={() => {
                            const anchor = document.querySelector(navItem.id)
                            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
                        }}>
                            {navItem.nav}
                        </li>
                    ))}
                    <a href="tel:2484993527" className="text-gray-700 hover:text-blue-600 font-semibold">248 499-3527</a>
                </ul>
            )}
        </nav>
    );
};

export default Navbar;
