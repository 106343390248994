import React from 'react'

import NavBar from '../components/NavBar';
import Hero from '../components/Hero';
import MobileForm from '../components/MobileForm';
import Info from '../components/Info';
import Services from '../components/Services';
import Areas from '../components/Areas';
// import Meet from '../components/Meet';
import InsuranceClaims from '../components/InsuranceClaims';
import Why from '../components/Why';
import ContactForm from '../components/Contact';
import FAQ from '../components/FAQ'
import Footer from '../components/Footer'

import ScrollToTop from '../components/ScrollToTop';

const MainScreen = () => {
    return (
        <div className="App bg-white">
            <NavBar />
            <Hero />
            <div className='md:hidden'>
                <MobileForm />
            </div>
            <Info />
            <Services />
            <Areas />
            <Why />
            <InsuranceClaims />
            <FAQ />
            <ContactForm />

            <Footer />
            <ScrollToTop className='' />
        </div>
    )
}

export default MainScreen