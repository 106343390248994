import React from 'react';
import Michigan from '../assets/images/michigan.png';
import { FaLocationDot } from "react-icons/fa6";

const Areas = () => {
    const areas = ['Oakland', 'Wayne', 'Macomb', 'Genesee', 'Washtenaw', 'Livingston', 'Lapeer', 'St Clair'];

    return (
        <div id='areas-section' className="py-12 bg-white md:mx-32 ">
            <div className='flex flex-col items-center md:flex-row md:justify-between'>
                <div className='text-center md:text-left'>
                    <h2 className="text-3xl md:text-4xl text-primary-blue font-bold">Areas We Serve</h2>
                    <p className='text-md'>
                        We Build Roofs, You Build Memories
                    </p>
                    <div className=" grid grid-cols-2 gap-2 pt-4">
                        {areas.map((loc, index) => (
                            <div key={index} className='flex flex-row items-center mx-auto md:mx-0'>
                                <FaLocationDot className='mr-2 text-primary-blue' />
                                <p className='text-lg'>{loc}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <img src={Michigan} alt="Map of service locations" className='w-1/2 mt-8 md:w-1/4 md:mt-0' />
            </div>
        </div>
    );
}

export default Areas;
