import React from 'react'

const FAQ = () => {


  return (

    <div id='faqs' className=" mx-4 md:px-32 bg-white min-h-sceen">
      <div className="flex flex-col items-center">
        <h2 className="font-bold text-4xl mt-5 tracking-tight">
          FAQ
        </h2>
        <p className="text-neutral-500 text-xl mt-3">
          Frequenty asked questions
        </p>
      </div>
      <div className="grid divide-y divide-neutral-200 max-w-xl mx-auto mt-8">
        <div className="py-5 text-left">
          <details className="group">
            <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
              <span> What types of roofing services do you offer?</span>
              <span className="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
              At MI Roof Pro, we provide a comprehensive range of roofing services, including:
            </p>
            <ul>
              <li className='list-disc ml-8 px-2'>Roof Repairs: Addressing leaks, damage, and other issues to extend the life of your roof.</li>
              <li className='list-disc ml-8 px-2'>Roof Replacements: Full replacement services for residential and commercial properties.</li>
              <li className='list-disc ml-8 px-2'>New Roof Installations: Expert installation of new roofing systems tailored to your needs.
              </li>
              <li className='list-disc ml-8 px-2'>Commercial Roofing: Specialized solutions for commercial buildings, including flat and sloped roofs.
              </li>
              <li className='list-disc ml-8 px-2'>Residential Roofing: Customized roofing services for homes, including various materials and styles.</li>
            </ul>
          </details>
        </div>
        <div className="py-5 text-left">
          <details className="group">
            <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
              <span> How do I know if my roof needs to be repaired or replaced?</span>
              <span className="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
              Determining whether your roof needs repair or replacement depends on several factors, including the extent of damage, the age of the roof, and the type of materials used. Signs that you might need a replacement include:
            </p>
            <ul>
              <li className='list-disc ml-8 px-2'>Frequent leaks or water damage</li>
              <li className='list-disc ml-8 px-2'>Significant wear and tear or missing shingles</li>
              <li className='list-disc ml-8 px-2'>New Roof Installations: Expert installation of new roofing systems tailored to your needs.
              </li>
              <li className='list-disc ml-8 px-2'>Advanced age of the roof (typically 20-30 years for asphalt shingles). Our team at MI Roof Pro can conduct a thorough inspection to provide a professional assessment and recommendation.
              </li>
            </ul>
          </details>
        </div>
        <div className="py-5 text-left">
          <details className="group">
            <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
              <span>What roofing materials do you recommend?
              </span>
              <span className="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
              The best roofing material for your property depends on various factors, including your budget, climate, and aesthetic preferences. We offer a range of high-quality materials, including:

            </p>
            <ul>
              <li className='list-disc ml-8 px-2'>Asphalt Shingles: Affordable and versatile, suitable for most homes.</li>
              <li className='list-disc ml-8 px-2'>Metal Roofing: Durable and energy-efficient, ideal for various climates.</li>
              <li className='list-disc ml-8 px-2'>Tile Roofing: Long-lasting and attractive, often used for its distinctive look.

              </li>
              <li className='list-disc ml-8 px-2'>Flat Roofing: Common for commercial buildings, available in materials like TPO and EPDM.
              </li>
            </ul>
          </details>
        </div>
        <div className="py-5 text-left">
          <details className="group">
            <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
              <span className=''> How long does a roofing project typically take?
              </span>
              <span className="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
              The duration of a roofing project depends on the complexity and size of the job. On average, a roof repair can take a day or two, while a full roof replacement may take several days to a week. Our team will provide a detailed timeline during the initial consultation to ensure you are informed of the project’s duration.
            </p>
          </details>
        </div>
        <div className="py-5 text-left">
          <details className="group">
            <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
              <span> Do you offer warranties on your roofing services?</span>
              <span className="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
              Yes, we offer comprehensive warranties on our roofing services to provide you with peace of mind. Our warranties cover both materials and workmanship, ensuring that you are protected against any issues that may arise after the project is completed. Details of our warranty options will be discussed during your consultation.
            </p>
          </details>
        </div>
        <div className="py-5 text-left">
          <details className="group">
            <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
              <span>What should I look for in a reliable roofing contractor?
              </span>
              <span className="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
              When choosing a roofing contractor, consider the following factors:
            </p>
            <ul>
              <li className='list-disc ml-8 px-2'>Experience and expertise in the type of roofing service you need</li>
              <li className='list-disc ml-8 px-2'>Positive customer reviews and a strong reputation
              </li>
              <li className='list-disc ml-8 px-2'>Proper licensing, insurance, and certifications
              </li>
              <li className='list-disc ml-8 px-2'>Clear, transparent pricing and a detailed written estimate
              </li>
              <li className='list-disc ml-8 px-2'>A commitment to safety and quality workmanship
              </li>
            </ul>
          </details>
        </div>
        <div className="py-5 text-left">
          <details className="group">
            <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
              <span> How can I schedule a roofing inspection or consultation?</span>
              <span className="transition group-open:rotate-180">
                <svg fill="none" height="24" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
              Scheduling a roofing inspection or consultation with MI Roof Pro is easy. Simply contact us via phone, email, or our online contact form. We will arrange a convenient time for one of our experts to visit your property, assess your roofing needs, and provide a detailed proposal.
            </p>
          </details>
        </div>

      </div>
    </div>


  )
}

export default FAQ