import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: "miroofpro.firebaseapp.com",
    projectId: "miroofpro",
    storageBucket: "miroofpro.appspot.com",
    messagingSenderId: "624144857182",
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: "G-RLS0E979EM"
};

export const phone_number = '2484993527'
export const fpn = '248 499-3527'

export const FIREBASE_APP = initializeApp(firebaseConfig);
export const FIREBASE_AUTH = getAuth(FIREBASE_APP)
export const FIRESTORE_DB = getFirestore(FIREBASE_APP)
export const FIREBASE_STORAGE = getStorage();