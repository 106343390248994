import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const MobileForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                process.env.REACT_APP_SERVICE_KEY,
                process.env.REACT_APP_TEMPLATE_ID,
                form.current,
                {
                    publicKey: process.env.REACT_APP_PUBLIC_KEY,
                }
            )
            .then(
                () => {
                    console.log('SUCCESS');
                    form.current.reset();

                    const button = document.getElementById('button');
                    button.textContent = 'MESSAGE SENT';
                },
                (error) => {
                    console.log('FAILED...', error.text);
                }
            );
    };

    return (

        <div
            id="quote-form"
            className="w-full max-w-lg mx-auto p-4 md:w-1/2 font-roboto"

        >
            <form
                className="bg-primary-blue p-6 md:rounded-sm shadow-md"
                ref={form}
                onSubmit={sendEmail}
            >
                <h1 className="text-lg font-bold text-white mb-4">Request A Free Quote</h1>

                <div className="mb-2">
                    <label
                        className="block text-white text-sm font-bold mb-1 text-left"
                        htmlFor="name"
                    >
                        Name
                    </label>

                    <input
                        className="w-full px-3 py-1 text-black border rounded-md focus:outline-none"
                        type="text"
                        id="name"
                        name="user_name"
                        placeholder="Your Name"
                        required
                    />
                </div>

                <div className="mb-2">
                    <label
                        className="block text-white text-sm font-bold mb-1 text-left"
                        htmlFor="email"
                    >
                        Email
                    </label>
                    <input
                        className="w-full px-3 py-1 text-black border rounded-md focus:outline-none"
                        type="email"
                        id="email"
                        name="user_email"
                        placeholder="Your Email"
                        required
                    />
                </div>

                <div className="mb-2">
                    <label
                        className="block text-white text-sm font-bold mb-1 text-left"
                        htmlFor="phone"
                    >
                        Phone
                    </label>
                    <input
                        className="w-full px-3 py-1 text-black border rounded-md focus:outline-none"
                        type="tel"
                        id="phone"
                        name="from_phone"
                        placeholder="Your Phone Number"
                        required
                    />
                </div>

                <div className="mb-2">
                    <label className="block text-white text-sm font-bold mb-1 text-left" htmlFor="service">
                        Select a Service
                    </label>
                    <select
                        name="service"
                        id="service"
                        className="w-full px-3 py-1 text-black border rounded-md focus:outline-none"
                        required
                    >
                        <option value="">Select an Option</option>
                        <option value="Roof Repair">Roof Repairs</option>
                        <option value="Roof Replacement">Roof Replacements</option>
                        <option value="New Roof Installation">New Roof Installation</option>
                        <option value="Commercial Roofing">Commercial Roofing</option>
                        <option value="Residential Roofing">Residential Roofing</option>
                    </select>
                </div>

                <div className="mb-2">
                    <label
                        className="block text-white text-sm font-bold mb-1 text-left"
                        htmlFor="description"
                    >
                        Project Description
                    </label>
                    <textarea
                        className="w-full px-3 py-1 text-black border rounded-md focus:outline-none"
                        id="description"
                        name="message"
                        rows="2"
                        placeholder="Project Description"
                        required
                    ></textarea>
                </div>

                <div className="flex justify-center">
                    <button
                        id="button"
                        className="bg-primary-yellow hover:bg-hover-yellow text-black py-2 px-8 rounded-sm font-semibold text-sm"
                        type="submit"
                        name="message"
                    >
                        SEND MESSAGE
                    </button>
                </div>
            </form>
        </div>
    );
};

export default MobileForm;
