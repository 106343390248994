import React, { useRef } from 'react';
import { fpn } from '../config/firebase.config';
import emailjs from '@emailjs/browser';

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {

        e.preventDefault();

        emailjs //SERVICE ID, TEMPLATE ID
            .sendForm(process.env.REACT_APP_SERVICE_KEY, process.env.REACT_APP_TEMPLATE_ID, form.current, {
                publicKey: process.env.REACT_APP_PUBLIC_KEY //PUBLIC KEY
            })
            .then(
                () => {
                    console.log('SUCCESS');
                    form.current.reset();

                    const button = document.getElementById('button');
                    button.textContent = "MESSAGE SENT";



                },
                (error) => {
                    console.log('FAILED...', error.text);
                }
            );
    };

    return (
        <div id='contact' className="bg-white py-12 md:px-32 md:text-left">
            <div className="flex flex-col items-center md:flex-row md:justify-between">
                <div className="md:w-1/2 md:pr-8">
                    <h2 className="text-3xl md:text-4xl text-primary-blue font-bold mb-4">Contact Us</h2>
                    <p className="text-md md:text-lg mb-8">
                        Have questions or need a quote? Reach out to us using the form below or give us a call.
                    </p>
                    <p className="text-md md:text-lg mb-4">
                        <strong>Phone:</strong> <a href="tel:2484993527" className="text-primary-blue">{fpn}</a>
                    </p>
                    <p className="text-md md:text-lg mb-4">
                        <strong>Email:</strong> <a href="mailto:reese@roofingsolutions.com" className="text-primary-blue">reese@miroofpro.com</a>
                    </p>
                </div>
                <div className="w-full md:w-1/2 mt-8 md:mt-0">
                    <form className="bg-primary-blue p-8 shadow-md" ref={form} onSubmit={sendEmail}>
                        <div className="mb-4">
                            <label className="block text-white text-sm font-bold mb-2 text-left" htmlFor="name">
                                Name
                            </label>
                            <input
                                className="w-full px-3 py-2 text-white  border border-gray-400 rounded-md focus:outline-none"
                                type="text"
                                id="name"
                                name="user_name"
                                placeholder="Your Name"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-white text-sm font-bold mb-2 text-left" htmlFor="email">
                                Email
                            </label>
                            <input
                                className="w-full px-3 py-2 text-black border border-gray-400 rounded-md focus:outline-none"
                                type="email"
                                id="email"
                                name="user_email"
                                placeholder="Your Email"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-white text-sm font-bold mb-2 text-left" htmlFor="message">
                                Message
                            </label>
                            <textarea
                                className="w-full px-3 py-2 text-black border border-gray-400 rounded-md focus:outline-none"
                                id="message"
                                name="message"
                                rows="5"
                                placeholder="Your Message"
                            ></textarea>
                        </div>
                        <div className="flex justify-center">
                            <button id='button' className="bg-primary-yellow text-black hover:bg-hover-yellow py-2 px-8 rounded-sm font-semibold text-sm hover:cursor-pointer transition-colors duration-300" type="submit">
                                SEND MESSAGE
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Contact;
