import React from 'react'
import './Card.css'

const ServiceCard = (props) => {
    console.log(props.service.image)
    return (
        // <div key={props.index} className="bg-white  p-4 h-44 w-36 text-left font-roboto hover:bg-[#bbd0e0]  hover:text-white hover:shadow-md">
        //     <h3 className="text-2xl font-semibold">{props.service.title}</h3>
        //     <div id='horizontal-line' className='w-[100%] h-[1px] my-2 bg-black group-hover:bg-white'></div>

        //     <p className='text-xs font-light'>Get service --</p>
        // </div>
        <div className="relative w-full md:w-48 h-60 md:h-72 bg-cover bg-center rounded-sm shadow-md overflow-hidden group font-roboto" style={{ backgroundImage: `url(${props.service.image})` }}>
            {/* Overlay */}
            <div className="absolute inset-0 bg-black opacity-50 transition-opacity duration-300 group-hover:opacity-75 z-0"></div>

            {/* Content */}
            <div className="absolute inset-0 flex flex-col justify-between items-center text-white p-4 md:p-5 text-center transition-opacity duration-300 group-hover:opacity-100 z-10">
                <h2 className="text-lg md:text-xl font-bold mb-2">{props.service.title}</h2>
                <p className="text-xs md:text-sm mb-3">{props.service.description}</p>
                <button className="bg-primary-blue text-xs md:text-sm text-white py-2 px-5 rounded-sm font-semibold transition-transform transform hover:scale-105">
                    Get This Service
                </button>
            </div>

            {/* Hover Effect */}
            <div className="absolute inset-0 bg-black opacity-0 transition-opacity duration-300 group-hover:opacity-50"></div>
        </div>
    )
}

export default ServiceCard